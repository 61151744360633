'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var maskServiceVfs = require('mask-service-vfs');
var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
require('rsuite/dist/styles/rsuite-default.css');
var CheckPicker = _interopDefault(require('rsuite/lib/CheckPicker'));
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));

//@ts-nocheck
var AvatarSize;
(function (AvatarSize) {
    AvatarSize["Small"] = "small";
    AvatarSize["Medium"] = "medium";
    AvatarSize["Large"] = "large";
    AvatarSize["Xlarge"] = "xlarge";
})(AvatarSize || (AvatarSize = {}));
const AvatarV = (props) => {
    const { activeOpacity, avatarStyle, containerStyle, editButton, icon, iconStyle, imageProps, onEditPress, onLongPress, onPress, overlayContainerStyle, placeholderStyle, rounded, size, showEditButton, source, title, titleStyle, renderPlaceholderContent, Component, ImageComponent, } = props;
    if (!title) {
        return (React__default.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, icon: icon && icon, iconStyle: iconStyle, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
    }
    return (React__default.createElement(reactNativeElements.Avatar, { activeOpacity: activeOpacity, avatarStyle: avatarStyle, containerStyle: containerStyle, editButton: editButton, imageProps: imageProps, onEditPress: onEditPress, onLongPress: onLongPress, onPress: onPress, overlayContainerStyle: overlayContainerStyle, placeholderStyle: placeholderStyle, rounded: rounded, size: size, showEditButton: showEditButton, source: source, title: title, titleStyle: titleStyle, renderPlaceholderContent: renderPlaceholderContent, Component: Component, ImageComponent: ImageComponent }));
};

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { checkPickerStyle: 'style' };
const delete_keys = [
    'containerStyle',
    'inline',
    'onChange',
    'title',
    'titleStyle',
    'titleContainerStyle',
    'maskParams',
    'helpblock',
    'errorMessage',
    'errorMessageStyle',
    'defaultValue',
];
const styles = reactNative.StyleSheet.create({
    linerStyle: {
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    textInlineStyle: { marginRight: 10, marginTop: 5 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
    iconContainerStyle: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    itemContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
const CheckPickerV = (props) => {
    var _a, _b;
    const maskServiceValidator = (value) => {
        let _value = value;
        let _rawValue = value;
        let _cc = '';
        if (props.maskParams && value && value !== '') {
            const _mask = new maskServiceVfs.MaskServiceV(props.maskParams.type, value, props.maskParams.options);
            _value = _mask.toMask();
            _rawValue = _mask.toRawValue();
            _cc = _mask.getCC();
        }
        return {
            maskValue: _value,
            rawValue: _rawValue,
            cc: _cc,
        };
    };
    const { maskValue, rawValue, cc } = maskServiceValidator((_a = props.defaultValue) !== null && _a !== void 0 ? _a : '');
    const [value, setValue] = React.useState(maskValue !== null && maskValue !== void 0 ? maskValue : '');
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const handleOnChange = (value, event) => {
        const { maskValue, rawValue, cc } = maskServiceValidator(value);
        props.onChange && props.onChange(rawValue, cc);
        setValue(maskValue);
    };
    const handleOnPressEnter = (event) => {
        props.onEnterPress && props.onEnterPress(value);
    };
    const _renderTitle = () => {
        return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                props.titleContainerStyle,
                { flexWrap: 'wrap' },
            ]) },
            React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                    props.titleStyle,
                    props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
                ]) }, props.title)));
    };
    const compare = (a, b) => {
        const nameA = a.toUpperCase();
        const nameB = b.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        props.title && _renderTitle(),
        React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                {
                    flexDirection: ((_b = props.helpblock) === null || _b === void 0 ? void 0 : _b.tooltip) && !props.errorMessage
                        ? 'row'
                        : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                },
            ]) },
            React__default.createElement(CheckPicker, Object.assign({}, _widgetProps, { style: reactNative.StyleSheet.flatten([props.checkPickerStyle]), onChange: handleOnChange, onPressEnter: handleOnPressEnter, sort: isGroup => {
                    if (isGroup) {
                        return (a, b) => {
                            return compare(a.groupTitle, b.groupTitle);
                        };
                    }
                    return (a, b) => {
                        return compare(a.value, b.value);
                    };
                }, renderMenuItem: (label, item) => {
                    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.itemContainerStyle]) },
                        (item === null || item === void 0 ? void 0 : item.avatar) ? (React__default.createElement(AvatarV, { source: { uri: item === null || item === void 0 ? void 0 : item.avatar }, rounded: true })) : null,
                        React__default.createElement(reactNative.View, null,
                            React__default.createElement(reactNative.Text, null, item === null || item === void 0 ? void 0 : item.label),
                            (item === null || item === void 0 ? void 0 : item.email) ? React__default.createElement(reactNative.Text, null, item === null || item === void 0 ? void 0 : item.email) : null)));
                } })),
            props.helpblock && !props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: props.helpblock.tooltip, style: reactNative.StyleSheet.flatten([props.helpblock.style]) }, props.helpblock.message)),
            props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten([
                    styles.errorMessageStyle,
                    props.errorMessageStyle,
                ]) }, props.errorMessage)))));
};

exports.CheckPickerV = CheckPickerV;
