module.exports = require('./dist');

if (require.main === module) {
  const {
    pretty
  } = require('./dist/pretty')
  console.log(pretty({
    name: "hello",
    vip: true,
    id: 1
  }));
}
