'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var maskServiceVfs = require('mask-service-vfs');
var React = require('react');
var React__default = _interopDefault(React);
var reactIconRs = require('react-icon-rs');
var reactNative = require('react-native');
require('rsuite/dist/styles/rsuite-default.css');
var AutoComplete = _interopDefault(require('rsuite/lib/AutoComplete'));
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));
var InputGroup = _interopDefault(require('rsuite/lib/InputGroup'));

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { autoCompleteStyle: 'style' };
const delete_keys = [
    'containerStyle',
    'inline',
    'onChange',
    'title',
    'titleStyle',
    'titleContainerStyle',
    'inputGroupStyle',
    'inside',
    'prefixComponent',
    'suffixComponent',
    'appearance',
    'maskParams',
    'helpblock',
    'errorMessage',
    'errorMessageStyle',
    'defaultValue',
];
const styles = reactNative.StyleSheet.create({
    linerStyle: {
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    suffixStyle: {},
    textInlineStyle: { marginRight: 10, marginTop: 5 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
    iconContainerStyle: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
});
const AutoCompleteV = (props) => {
    var _a, _b;
    const maskServiceValidator = (value) => {
        let _value = value;
        let _rawValue = value;
        let _cc = '';
        if (props.maskParams && value && value !== '') {
            const _mask = new maskServiceVfs.MaskServiceV(props.maskParams.type, value, props.maskParams.options);
            _value = _mask.toMask();
            _rawValue = _mask.toRawValue();
            _cc = _mask.getCC();
        }
        return {
            maskValue: _value,
            rawValue: _rawValue,
            cc: _cc,
        };
    };
    const { maskValue, rawValue, cc } = maskServiceValidator((_a = props.defaultValue) !== null && _a !== void 0 ? _a : '');
    const [value, setValue] = React.useState(maskValue !== null && maskValue !== void 0 ? maskValue : '');
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const handleOnChange = (value, event) => {
        const { maskValue, rawValue, cc } = maskServiceValidator(value);
        props.onChange && props.onChange(rawValue, cc);
        setValue(maskValue);
    };
    const handleOnPressEnter = (event) => {
        props.onEnterPress && props.onEnterPress(value);
    };
    const _renderSuffixComponent = () => {
        if (props.suffixComponent) {
            if (Array.isArray(props.suffixComponent)) {
                return (React__default.createElement(InputGroup.Addon, null,
                    React__default.createElement(reactIconRs.IconV, { icons: props.suffixComponent, containerStyle: props.inside ? styles.iconContainerStyle : undefined })));
            }
            return React__default.createElement(InputGroup.Addon, null, props.suffixComponent);
        }
        return null;
    };
    const _renderPrefixComponent = () => {
        if (props.prefixComponent) {
            if (Array.isArray(props.prefixComponent)) {
                return (React__default.createElement(InputGroup.Addon, null,
                    React__default.createElement(reactIconRs.IconV, { icons: props.prefixComponent, containerStyle: props.inside ? styles.iconContainerStyle : undefined })));
            }
            return React__default.createElement(InputGroup.Addon, null, props.prefixComponent);
        }
        return null;
    };
    const _renderTitle = () => {
        return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                props.titleContainerStyle,
                { flexWrap: 'wrap' },
            ]) },
            React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                    props.titleStyle,
                    props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
                ]) }, props.title)));
    };
    const _renderBody = () => {
        return (React__default.createElement(InputGroup, { inside: props.inside, style: reactNative.StyleSheet.flatten([props.inputGroupStyle]) },
            _renderPrefixComponent(),
            React__default.createElement(AutoComplete, Object.assign({}, _widgetProps, { style: reactNative.StyleSheet.flatten([props.autoCompleteStyle]), onChange: handleOnChange, onPressEnter: handleOnPressEnter })),
            _renderSuffixComponent()));
    };
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        props.title && _renderTitle(),
        React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                {
                    flexDirection: ((_b = props.helpblock) === null || _b === void 0 ? void 0 : _b.tooltip) && !props.errorMessage
                        ? 'row'
                        : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                },
            ]) },
            _renderBody(),
            props.helpblock && !props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: props.helpblock.tooltip, style: reactNative.StyleSheet.flatten([props.helpblock.style]) }, props.helpblock.message)),
            props.errorMessage && (React__default.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten([
                    styles.errorMessageStyle,
                    props.errorMessageStyle,
                ]) }, props.errorMessage)))));
};

exports.AutoCompleteV = AutoCompleteV;
