'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var maskServiceVfs = require('mask-service-vfs');
var React = _interopDefault(require('react'));
var reactNative = require('react-native');
var rsuite = require('rsuite');
require('rsuite/dist/styles/rsuite-default.css');
var HelpBlock = _interopDefault(require('rsuite/lib/HelpBlock'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var Uploader = _interopDefault(require('rsuite/lib/Uploader'));

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { uploaderStyle: 'style' };
const delete_keys = [
    'containerStyle',
    'inline',
    'onChange',
    'title',
    'titleStyle',
    'titleContainerStyle',
    'maskParams',
    'helpblock',
    'errorMessage',
    'errorMessageStyle',
    'defaultFileList',
    'iconContainerStyle',
    'cameraRetro',
    'avatarButtonStyles',
    'avatarName',
    'containerText',
    'draggerContainerStyle',
];
const styles = reactNative.StyleSheet.create({
    linerStyle: {
        borderTopColor: 'transparent',
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        backgroundColor: 'transparent',
        borderRadius: 0,
    },
    textInlineStyle: { marginRight: 10, marginTop: 5 },
    textNonInlineStyle: { marginBottom: 10 },
    errorMessageStyle: {
        color: 'red',
        marginTop: 5,
        marginLeft: 5,
        fontSize: 11,
    },
    iconContainerStyle: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',
    },
    buttonStyle: {
        width: 150,
        height: 150,
    },
    draggerContainerStyle: {
        lineHeight: '200px',
    },
});
const UploaderV = (props) => {
    var _a, _b;
    const maskServiceValidator = (value) => {
        let _value = value;
        let _rawValue = value;
        let _cc = '';
        if (props.maskParams && value && value !== '') {
            const _mask = new maskServiceVfs.MaskServiceV(props.maskParams.type, value, props.maskParams.options);
            _value = _mask.toMask();
            _rawValue = _mask.toRawValue();
            _cc = _mask.getCC();
        }
        return {
            maskValue: _value,
            rawValue: _rawValue,
            cc: _cc,
        };
    };
    const { maskValue, rawValue, cc } = maskServiceValidator((_a = props.defaultFileList) !== null && _a !== void 0 ? _a : '');
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const previewFile = (file, callback) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    };
    const handleUpload = (file) => {
        setUploading(true);
        previewFile(file.blobFile, value => {
            setFileInfo(value);
        });
    };
    const handleSuccess = (response, file) => {
        setUploading(false);
        rsuite.Alert.success('Uploaded successfully');
        // console.log(response);
        (props === null || props === void 0 ? void 0 : props.onSuccess) && (props === null || props === void 0 ? void 0 : props.onSuccess(response, file));
    };
    const handleError = (reason, file) => {
        setFileInfo(null);
        setUploading(false);
        rsuite.Alert.error('Upload failed');
        // console.log(reason);
    };
    const handleOnChange = (fileList) => {
        const { maskValue, rawValue, cc } = maskServiceValidator(fileList);
        props.onChange && props.onChange(rawValue, cc);
        setFileInfo(maskValue);
    };
    const _renderTitle = () => {
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                props.titleContainerStyle,
                { flexWrap: 'wrap' },
            ]) },
            React.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                    props.titleStyle,
                    props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
                ]) }, props.title)));
    };
    const _getButton = () => {
        var _a, _b, _c, _d, _e, _f, _g;
        if (props.cameraRetro) {
            return (React.createElement("button", null,
                React.createElement(Icon, { style: props.iconContainerStyle, icon: (_a = props.avatarName) !== null && _a !== void 0 ? _a : 'camera-retro', size: (_b = props.avatarIconSize) !== null && _b !== void 0 ? _b : '5x' })));
        }
        else if (props.avatar) {
            return (React.createElement("button", { style: reactNative.StyleSheet.flatten([
                    (_c = props.avatarButtonStyles) !== null && _c !== void 0 ? _c : styles.buttonStyle,
                ]) },
                uploading && React.createElement(rsuite.Loader, { backdrop: true, center: true }),
                fileInfo ? (React.createElement("img", { src: fileInfo, width: "100%", height: "100%" })) : (React.createElement(Icon, { style: props.iconContainerStyle, icon: (_d = props.avatarName) !== null && _d !== void 0 ? _d : 'avatar', size: (_e = props.avatarIconSize) !== null && _e !== void 0 ? _e : '5x' }))));
        }
        else if (props.draggable) {
            return (React.createElement("div", { style: reactNative.StyleSheet.flatten([
                    (_f = props.draggerContainerStyle) !== null && _f !== void 0 ? _f : styles.draggerContainerStyle,
                ]) }, (_g = props.containerText) !== null && _g !== void 0 ? _g : 'Click or Drag files to this area to upload'));
        }
    };
    const _renderBody = () => {
        return (React.createElement(Uploader, Object.assign({}, _widgetProps, { style: reactNative.StyleSheet.flatten([props.uploaderStyle]), onUpload: handleUpload, onSuccess: handleSuccess, onError: handleError, onChange: handleOnChange }), _getButton()));
    };
    return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.containerStyle,
            props.inline && { flexDirection: 'row' },
        ]) },
        props.title && _renderTitle(),
        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                {
                    flexDirection: ((_b = props.helpblock) === null || _b === void 0 ? void 0 : _b.tooltip) && !props.errorMessage
                        ? 'row'
                        : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                },
            ]) },
            _renderBody(),
            props.helpblock && !props.errorMessage && (React.createElement(HelpBlock, { tooltip: props.helpblock.tooltip, style: reactNative.StyleSheet.flatten([props.helpblock.style]) }, props.helpblock.message)),
            props.errorMessage && (React.createElement(HelpBlock, { tooltip: false, style: reactNative.StyleSheet.flatten([
                    styles.errorMessageStyle,
                    props.errorMessageStyle,
                ]) }, props.errorMessage)))));
};

exports.UploaderV = UploaderV;
