'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var reactViewportProvider = require('react-viewport-provider');
require('rsuite/dist/styles/rsuite-default.css');
var Container = _interopDefault(require('rsuite/lib/Container'));
var Content = _interopDefault(require('rsuite/lib/Content'));
var Breadcrumb = _interopDefault(require('rsuite/lib/Breadcrumb'));
var Footer = _interopDefault(require('rsuite/lib/Footer'));
var rsuite = require('rsuite');
var Avatar = _interopDefault(require('rsuite/lib/Avatar'));
var Badge = _interopDefault(require('rsuite/lib/Badge'));
var Divider = _interopDefault(require('rsuite/lib/Divider'));
var Header = _interopDefault(require('rsuite/lib/Header'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var Nav = _interopDefault(require('rsuite/lib/Nav'));
var Navbar = _interopDefault(require('rsuite/lib/Navbar'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var Dropdown = _interopDefault(require('rsuite/lib/Dropdown'));
var FlexboxGrid = _interopDefault(require('rsuite/lib/FlexboxGrid'));
var IconButton = _interopDefault(require('rsuite/lib/IconButton'));
var Sidebar = _interopDefault(require('rsuite/lib/Sidebar'));
var Sidenav = _interopDefault(require('rsuite/lib/Sidenav'));

const ids = require('short-id');
const BreadcrumbsV = (props) => {
    const onBreadcrumbsClick = (element) => {
        props.onBreadcrumbsClick && props.onBreadcrumbsClick(element.key);
    };
    const _renderBreadcrumbs = () => {
        let _list = [];
        if (props.breadcrumbs && Array.isArray(props.breadcrumbs)) {
            props.breadcrumbs.forEach((element, index) => {
                _list.push(React__default.createElement(Breadcrumb.Item, { key: ids.generate() },
                    React__default.createElement("span", { onClick: () => onBreadcrumbsClick(element) }, element.title)));
            });
        }
        return _list;
    };
    return React__default.createElement(Breadcrumb, { style: { marginBottom: 0 } }, _renderBreadcrumbs());
};

const ids$1 = require('short-id');
const FooterV = (props) => {
    const { height } = reactViewportProvider.useViewport();
    const styles = {
        footerStyle: {
            borderTopWidth: 1,
            borderColor: '#C1C1C1',
            boxShadow: '#C1C1C1',
            // width: props.sidebarFixedWidth && props.layout === 'sidebar-header-content-footer' &&
            //   (props.sideBarItems)
            //   ? width - props.sidebarFixedWidth : '100%',
            height: 0.02 * height,
        },
    };
    const renderFooter = () => {
        let _footer = [];
        if (props.renderFooter) {
            _footer.push(React__default.createElement(reactNative.View, { key: ids$1.generate(), style: reactNative.StyleSheet.flatten([styles.footerStyle]) }, props.renderFooter()));
        }
        return _footer;
    };
    return React__default.createElement(Footer, null, renderFooter());
};

//@ts-nocheck
const ids$2 = require('short-id');
const NAV_BAR_BG_COLOR = '#F4F4F4';
const RIGHT_AVATAR_BG_COLOR = '#FFFFFF';
const TITLE_COLOR = '#717171';
const NAV_BAR_HEIGHT = 56;
const RenderCustomItem = (item, cb) => {
    if (cb) {
        return React__default.createElement("div", null, cb(item));
    }
    return React__default.createElement("div", null);
};
const HeaderVInternal = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const [activeKey, setActiveKey] = React.useState(null);
    const { width } = reactViewportProvider.useViewport();
    const _headerHeight = (_a = props.headerHeight) !== null && _a !== void 0 ? _a : NAV_BAR_HEIGHT;
    const styles = {
        navbarStyle: {
            backgroundColor: (_b = props.headerBackgroundColor) !== null && _b !== void 0 ? _b : NAV_BAR_BG_COLOR,
            boxShadow: `1px 1px ${(_c = props.headerboxShadowColor) !== null && _c !== void 0 ? _c : '#C1C1C1'}`,
        },
        avatarStyle: {
            background: 'transparent',
            width: 100,
        },
        headerStyle: {
            display: 'flex',
            flexDirection: 'column',
        },
        headerTitleContainerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            height: (_d = props.headerHeight) !== null && _d !== void 0 ? _d : NAV_BAR_HEIGHT,
        },
        headerTitleStyle: {
            color: (_e = props.headerTitleColor) !== null && _e !== void 0 ? _e : TITLE_COLOR,
            fontWeight: 'bold',
            fontSize: 22,
        },
        rightAvatarStyle: {
            background: (_f = props.avatarBackgroundColor) !== null && _f !== void 0 ? _f : RIGHT_AVATAR_BG_COLOR,
            marginTop: 12,
            color: (_g = props.headerTitleColor) !== null && _g !== void 0 ? _g : TITLE_COLOR,
        },
        navItemTitleStyle: {
            color: (_h = props.headerTitleColor) !== null && _h !== void 0 ? _h : TITLE_COLOR,
            fontWeight: '500',
        },
        navbarItemIconStyle: {
            color: (_j = props.headerTitleColor) !== null && _j !== void 0 ? _j : TITLE_COLOR,
        },
        blockGroupContainer: {
            background: props.primaryColor,
            //marginRight: 10,
            height: _headerHeight - 0,
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
    const renderNavbarHeader = () => {
        const _header = [];
        if (props.headerLogo) {
            _header.push(React__default.createElement(Avatar, { key: ids$2.generate(), circle: true, src: props.headerLogo, size: 'lg', style: reactNative.StyleSheet.flatten([
                    styles.avatarStyle,
                    props.headerLogoStyle,
                ]) }));
        }
        return _header;
    };
    const renderIcon = (icon, eventKey, badge) => {
        if (icon) {
            if (badge) {
                return (React__default.createElement(Badge, { content: badge },
                    React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                            styles.navbarItemIconStyle,
                            icon.style,
                        ]) }))));
            }
            return (React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                    styles.navbarItemIconStyle,
                    icon.style,
                ]) })));
        }
        return null;
    };
    const renderNavBarItems = (navBarItems) => {
        var _a, _b, _c;
        let _items = [];
        if (navBarItems && Array.isArray(navBarItems)) {
            for (let i = 0; i < navBarItems.length; ++i) {
                const _item = navBarItems[i];
                if (_item.appearance === 'avatar') {
                    _items.push(React__default.createElement("div", { style: { cursor: 'pointer' } },
                        React__default.createElement(Avatar, { key: ids$2.generate(), circle: true, src: (_a = _item.avatar) === null || _a === void 0 ? void 0 : _a.src, alt: (_b = _item.avatar) === null || _b === void 0 ? void 0 : _b.title, size: 'sm', style: reactNative.StyleSheet.flatten([styles.rightAvatarStyle]), onClick: () => props.onNavItemSelect && props.onNavItemSelect(_item.eventKey) }, (_c = _item.avatar) === null || _c === void 0 ? void 0 : _c.title)));
                }
                else if (_item.appearance === 'block') {
                    _items.push(React__default.createElement(Nav.Item, { key: ids$2.generate(), eventKey: _item.eventKey, icon: renderIcon(_item.icon, _item.eventKey, _item.badge), style: reactNative.StyleSheet.flatten([
                            styles.blockGroupContainer,
                            _item.style,
                            _item.color && {
                                backgroundColor: _item.color,
                            },
                        ]) }, _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                            _item.titleStyle,
                        ]) }, _item.title))));
                }
                else if (_item.appearance === 'custom') {
                    if (props.renderCustomNavbarItem) {
                        _items.push(React__default.createElement(Nav.Item, { key: ids$2.generate(), eventKey: _item.eventKey, renderItem: RenderCustomItem.bind(null, _item, props.renderCustomNavbarItem) }));
                    }
                }
                else if (_item.appearance === 'drop-down') {
                    _items.push(
                    // <Dropdown key={ids.generate()} title={_item.title}>
                    //   {renderDropDownItems(_item.dropdownItems)}
                    // </Dropdown>,
                    React__default.createElement(rsuite.SelectPicker, { data: _item.dropdownItems, style: _item.selectStyle, defaultValue: _item.defaultValue }));
                }
                else {
                    _items.push(React__default.createElement(Nav.Item, { key: ids$2.generate(), eventKey: _item.eventKey, icon: renderIcon(_item.icon, _item.eventKey, _item.badge) }, _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                        ]) }, _item.title))));
                }
            }
        }
        return _items;
    };
    const handleNavItemSelect = (activeKey) => {
        setActiveKey(activeKey);
        props.onNavItemSelect && props.onNavItemSelect(activeKey);
    };
    return (React__default.createElement(Header, { style: reactNative.StyleSheet.flatten([styles.headerStyle]) },
        React__default.createElement(Navbar, { style: reactNative.StyleSheet.flatten([styles.navbarStyle, props.navbarStyle]) },
            React__default.createElement(Navbar.Header, null, renderNavbarHeader()),
            React__default.createElement(Navbar.Body, null,
                props.headerTitle && (React__default.createElement(Nav, null,
                    React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.headerTitleContainerStyle]) },
                        React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                                styles.headerTitleStyle,
                                props.headerTitleStyle,
                            ]) }, props.headerTitle)))),
                props.navBarLeftItems && (React__default.createElement(Nav, { activeKey: activeKey, onSelect: handleNavItemSelect }, renderNavBarItems(props.navBarLeftItems))),
                props.navBarRightItems && (React__default.createElement(Nav, { pullRight: true, activeKey: activeKey, onSelect: handleNavItemSelect }, renderNavBarItems(props.navBarRightItems)))))));
};
const HeaderV = (props) => {
    return (React__default.createElement(reactViewportProvider.ViewportProvider, null,
        React__default.createElement(HeaderVInternal, Object.assign({}, props))));
};

//@ts-nocheck
const ids$3 = require('short-id');
const EXPANDABLE_WIDTH = 260;
const COLLAPSIBLE_WIDTH = 56;
const IS_SIDEBAR_COLLAPSIBLE = true;
const SIDE_BAR_APPERANCE = 'default';
const NAV_BAR_BG_COLOR$1 = '#F4F4F4';
const RIGHT_AVATAR_BG_COLOR$1 = '#FFFFFF';
const TITLE_COLOR$1 = '#C1C1C1';
const NAV_BAR_HEIGHT$1 = 56;
const DROP_DOWN_PLACEMENT = 'right-start';
const SidebarV = (props) => {
    const [expand, setExpand] = React.useState(true);
    const [activeKey, setActiveKey] = React.useState(null);
    const [hover, setHover] = React.useState({});
    const { height } = reactViewportProvider.useViewport();
    const styles = {
        sidebar: {
            position: 'fixed',
            display: 'flex',
            flexDirection: 'column',
            height: 1 * height - 56,
            backgroundColor: props.primaryColor,
        },
        navbarStyle: {
            backgroundColor: NAV_BAR_BG_COLOR$1,
            boxShadow: '1px 1px #C1C1C1',
        },
        avatarStyle: {
            background: 'transparent',
            width: 100,
        },
        headerStyle: {},
        headerTitleContainerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            height: NAV_BAR_HEIGHT$1,
        },
        headerTitleStyle: {
            color: TITLE_COLOR$1,
            fontWeight: 'bold',
            fontSize: 22,
        },
        rightAvatarStyle: {
            background: RIGHT_AVATAR_BG_COLOR$1,
            marginTop: 12,
            color: TITLE_COLOR$1,
        },
        navItemTitleStyle: {
            color: TITLE_COLOR$1,
            fontWeight: '500',
            overflow: 'hidden',
        },
        navbarItemIconStyle: {
            color: TITLE_COLOR$1,
        },
        sidenavBodyStyle: {
            backgroundColor: props.primaryColor,
        },
        sidebarClosureIconStyle: {
            width: 30,
            backgroundColor: 'transparent',
            // boxShadow: '2px 2px #C1C1C1',
            borderColor: '#000000',
        },
        subMenuContainerStyle: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }
    };
    const renderHeader = () => {
        const _header = [];
        if (props.renderSidenavHeader) {
            _header.push(React__default.createElement(Sidenav.Header, { key: ids$3.generate() }, props.renderSidenavHeader()));
        }
        return _header;
    };
    const handleSideNavItemSelect = (activeKey) => {
        setActiveKey(activeKey);
        props.onSideNavItemSelect && props.onSideNavItemSelect(activeKey);
    };
    const renderDropDownItems = (items) => {
        let _items = [];
        if (items && Array.isArray(items)) {
            for (let i = 0; i < items.length; ++i) {
                _items.push(React__default.createElement(Dropdown.Item, { key: ids$3.generate(), eventKey: items[i].eventKey },
                    items[i].title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                            activeKey === items[i].eventKey &&
                                props.ternaryColor && {
                                color: props.ternaryColor,
                            },
                        ]) }, items[i].title)),
                    items[i].divider && (React__default.createElement(reactNative.View, { style: { marginTop: 15 } },
                        React__default.createElement(Divider, { style: { padding: 0, margin: 0 } })))));
            }
        }
        return _items;
    };
    const renderIcon = (icon, eventKey, badge) => {
        if (icon) {
            if (badge) {
                return (React__default.createElement(Badge, { content: badge },
                    React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                            styles.navbarItemIconStyle,
                            icon.style,
                            activeKey === eventKey &&
                                props.secondaryColor && { color: props.secondaryColor },
                        ]) }))));
            }
            return (React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                    styles.navbarItemIconStyle,
                    icon.style,
                    activeKey === eventKey &&
                        props.secondaryColor && { color: props.secondaryColor },
                ]) })));
        }
        return null;
    };
    const handleMouseEnter = (eventKey) => {
        const _hover = Object.assign({}, hover);
        for (let ek in _hover) {
            _hover[ek] = false;
        }
        _hover[eventKey] = true;
        setHover(Object.assign({}, _hover));
    };
    const handleMouseLeave = (eventKey) => {
        const _hover = Object.assign({}, hover);
        for (let ek in _hover) {
            _hover[ek] = false;
        }
        setHover(Object.assign({}, _hover));
    };
    const renderNavItems = () => {
        var _a, _b, _c;
        let _items = [];
        if (props.sideBarItems && Array.isArray(props.sideBarItems)) {
            for (let i = 0; i < props.sideBarItems.length; ++i) {
                const _item = props.sideBarItems[i];
                if (_item.appearance === 'avatar') {
                    _items.push(React__default.createElement(Avatar, { key: ids$3.generate(), circle: true, src: (_a = _item.avatar) === null || _a === void 0 ? void 0 : _a.src, alt: (_b = _item.avatar) === null || _b === void 0 ? void 0 : _b.title, size: 'sm', style: reactNative.StyleSheet.flatten([styles.rightAvatarStyle]), onClick: () => props.onSideNavItemSelect &&
                            props.onSideNavItemSelect(_item.eventKey) }, (_c = _item.avatar) === null || _c === void 0 ? void 0 : _c.title));
                }
                else if (_item.appearance === 'drop-down') {
                    _items.push(React__default.createElement(Dropdown, { key: ids$3.generate(), title: _item.title, placement: DROP_DOWN_PLACEMENT }, renderDropDownItems(_item.dropdownItems)));
                }
                else if (_item.appearance === 'sub-menu') {
                    _items.push(React__default.createElement(reactFlexboxGridRs.FBGridV, { align: 'middle', justify: 'space-between' },
                        activeKey === _item.eventKey &&
                            props.secondaryColor &&
                            _item.height && (React__default.createElement(reactFlexboxGridRs.FBItemV, { colspan: 2 },
                            React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([{
                                        backgroundColor: props.primaryHighlightColor,
                                        height: _item.height,
                                        alignItems: 'center'
                                    }]) },
                                React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([{
                                            backgroundColor: props.secondaryColor,
                                            height: _item.height / 2,
                                            top: _item.height / 4,
                                            width: 1.8,
                                        }]) })))),
                        React__default.createElement(reactFlexboxGridRs.FBItemV, { colspan: activeKey === _item.eventKey ? 22 : 24 },
                            React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                                    styles.subMenuContainerStyle,
                                    _item.style,
                                    _item.height && { height: _item.height },
                                    { cursor: 'pointer' },
                                    activeKey === _item.eventKey &&
                                        props.primaryHighlightColor && {
                                        backgroundColor: props.primaryHighlightColor,
                                    },
                                ]), onClick: handleSideNavItemSelect.bind(null, _item.eventKey) },
                                renderIcon(_item.icon, _item.eventKey, _item.badge),
                                _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                                        styles.navItemTitleStyle,
                                        activeKey === _item.eventKey &&
                                            props.ternaryColor && {
                                            color: props.ternaryColor,
                                        },
                                    ]) }, _item.title))))));
                }
                else {
                    _items.push(React__default.createElement(Nav.Item, { key: ids$3.generate(), eventKey: _item.eventKey, onMouseEnter: handleMouseEnter.bind(null, _item.eventKey), onMouseLeave: handleMouseLeave.bind(null, _item.eventKey), icon: renderIcon(_item.icon, _item.eventKey, _item.badge), style: reactNative.StyleSheet.flatten([
                            {},
                            activeKey === _item.eventKey &&
                                props.primaryHighlightColor && {
                                backgroundColor: props.primaryHighlightColor,
                            },
                        ]) },
                        React__default.createElement(FlexboxGrid, { justify: 'space-between' },
                            React__default.createElement(FlexboxGrid.Item, { colspan: expand ? 22 : 24 }, _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                                    styles.navItemTitleStyle,
                                    activeKey === _item.eventKey &&
                                        props.ternaryColor && {
                                        color: props.ternaryColor,
                                    },
                                ]) }, _item.title))),
                            !props.sidebarFixedWidth && expand && (React__default.createElement(FlexboxGrid.Item, { colspan: 2 }, activeKey === _item.eventKey && props.secondaryColor && (React__default.createElement(Icon, { icon: 'chevron-right', style: { color: props.secondaryColor } })))))));
                }
            }
        }
        return _items;
    };
    const renderSidenav = () => {
        const _sidenav = [];
        if (props.sideBarItems && Array.isArray(props.sideBarItems)) {
            _sidenav.push(React__default.createElement(Sidenav, { expanded: props.sidebarFixedWidth ? true : expand, defaultOpenKeys: props.defaultSideBarOpenKeys, appearance: SIDE_BAR_APPERANCE, activeKey: activeKey, onSelect: handleSideNavItemSelect, key: ids$3.generate() },
                React__default.createElement(Sidenav.Body, null,
                    React__default.createElement(Nav, { style: reactNative.StyleSheet.flatten([styles.sidenavBodyStyle]) }, renderNavItems()))));
        }
        return _sidenav;
    };
    const handleToggle = () => {
        setExpand(!expand);
    };
    const renderFloatingIcon = () => {
        if (!props.sidebarFixedWidth) {
            return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                    reactNative.StyleSheet.absoluteFill,
                    {
                        top: 58,
                        left: expand ? EXPANDABLE_WIDTH - 32 : COLLAPSIBLE_WIDTH - 22,
                    },
                ]) },
                React__default.createElement(IconButton, { icon: React__default.createElement(Icon, { icon: expand ? 'angle-double-left' : 'angle-double-right', size: '2x' }), onClick: handleToggle, style: reactNative.StyleSheet.flatten([styles.sidebarClosureIconStyle]), color: "blue" })));
        }
        return null;
    };
    return (React__default.createElement(Sidebar, { style: reactNative.StyleSheet.flatten([styles.sidebar, props.sidebarStyle]), width: props.sidebarFixedWidth
            ? props.sidebarFixedWidth
            : expand
                ? EXPANDABLE_WIDTH
                : COLLAPSIBLE_WIDTH, collapsible: !props.sidebarFixedWidth
            ? IS_SIDEBAR_COLLAPSIBLE
            : IS_SIDEBAR_COLLAPSIBLE },
        renderHeader(),
        renderSidenav(),
        renderFloatingIcon()));
};

//@ts-nocheck
const BG_COLOR = '#EAEAEA';
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = ['sidenavStyle', 'renderHeader', 'renderBody', 'activeKey'];
const BodyTemplateVInternal = (props) => {
    const { width, height } = reactViewportProvider.useViewport();
    const _props = props ? Object.assign({}, props) : {};
    deleteKeys(_props, delete_keys);
    const styles = {
        container: {
            flex: 1,
            width: '100%',
            backgroundColor: BG_COLOR,
        },
        contentStyle: {
            width: `100% - ${props.sidebarFixedWidth ? props.sidebarFixedWidth : 0}`,
            // overflowY: 'scroll',
            marginLeft: props.sidebarFixedWidth ? props.sidebarFixedWidth : 0,
        },
        bodyContainer: {
        // width: 0.9 * width
        },
    };
    const renderContent = () => {
        return (React__default.createElement(Content, { style: reactNative.StyleSheet.flatten([
                styles.contentStyle,
            ]) },
            props.breadcrumbs && React__default.createElement(BreadcrumbsV, Object.assign({}, props)),
            props.renderContent && props.renderContent()));
    };
    const renderDashboardLayout = () => {
        if (props.layout === 'sidebar-header-content-footer') {
            return (React__default.createElement(Container, null,
                props.sideBarItems && React__default.createElement(SidebarV, Object.assign({}, props)),
                React__default.createElement(Container, { style: reactNative.StyleSheet.flatten(styles.bodyContainer) },
                    (props.navBarLeftItems || props.navBarRightItems) && (React__default.createElement(HeaderV, Object.assign({}, props))),
                    renderContent(),
                    props.renderFooter && React__default.createElement(FooterV, Object.assign({}, props)))));
        }
        if (props.layout === 'header-content-sidebar-footer') {
            return (React__default.createElement(Container, null,
                (props.navBarLeftItems || props.navBarRightItems) && (React__default.createElement(HeaderV, Object.assign({}, props))),
                React__default.createElement(Container, null, props.sideBarItems && React__default.createElement(SidebarV, Object.assign({}, props))),
                props.renderFooter && React__default.createElement(FooterV, Object.assign({}, props))));
        }
        return (React__default.createElement(Container, null,
            React__default.createElement(HeaderV, Object.assign({}, props)),
            React__default.createElement(Container, null,
                React__default.createElement(SidebarV, Object.assign({}, props)),
                renderContent()),
            React__default.createElement(FooterV, Object.assign({}, props))));
    };
    return (
    // <View
    //   style={StyleSheet.flatten([
    //     styles.container,
    //     props.dashboardContainerStyle,
    //     props.dashboardContainerStyle?.backgroundColor && {
    //       backgroundColor: props.dashboardContainerStyle?.backgroundColor,
    //     },
    //   ])}
    // >
    React__default.createElement(React__default.Fragment, null, renderDashboardLayout())
    // </View>
    );
};
const BodyTemplateV = (props) => {
    return (React__default.createElement(reactViewportProvider.ViewportProvider, null,
        React__default.createElement(BodyTemplateVInternal, Object.assign({}, props))));
};

exports.BodyTemplateV = BodyTemplateV;
